const navigation = [
    {
        id: 'sas-practise-projects',
        path: 'accountant.projects',
        label: 'accountant.sidebar.projects',
        icon: 'line-icons:images:image-user',
        parent: 'sas-practise',
        position: 3,
    },
]

export default navigation
