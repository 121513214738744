import { isNil, sortBy, uniqBy } from 'lodash-es'

const getNavigations = (navigationModules) => {
    let parentMenus = []
    let childMenus = []

    for (const path in navigationModules) {
        navigationModules[path].default.forEach((menu) => {
            if (isNil(menu.parent)) {
                parentMenus.push(menu)
            } else {
                childMenus.push(menu)
            }
        })
    }

    parentMenus = uniqBy(parentMenus, 'id')
    childMenus = uniqBy(childMenus, 'id')

    sortBy(childMenus, ['position']).forEach((menu) => {
        const parentMenu = parentMenus.find(
            (parent) => parent.id === menu.parent
        )

        if (parentMenu) {
            parentMenu.children = parentMenu.children || []
            parentMenu.children.push(menu)
        } else {
            parentMenus.push(menu)
        }
    })

    return sortBy(parentMenus, ['position'])
}

export const tenantNavigations = getNavigations(
    import.meta.globEager('@tenant/modules/tenant/**/**/navigation.js')
)

export const accountantNavigations = getNavigations(
    import.meta.globEager('@tenant/modules/accountant/**/**/navigation.js')
)
