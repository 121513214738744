const navigation = [
    {
        id: 'sas-practise-team',
        path: 'accountant.team',
        label: 'accountant.sidebar.team',
        icon: 'line-icons:general:building-01',
        parent: 'sas-practise',
        position: 3,
    },
]

export default navigation
