const navigation = [
    {
        id: 'sas-practise-inbox',
        path: 'accountant.inbox',
        parent: 'sas-practise',
        position: 4,
        component: 'accountant-inbox-sidebar',
    },
]

export default navigation
