const navigation = [
    {
        id: 'sas-practise',
        path: 'accountant',
        label: 'accountant.sidebar.yourPractise',
        position: 1,
    },
]

export default navigation
