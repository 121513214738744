const navigation = [
    {
        id: 'sas-practise-clients',
        path: 'accountant.clients',
        label: 'accountant.sidebar.clients',
        icon: 'line-icons:images:image-user',
        parent: 'sas-practise',
        position: 2,
    },
]

export default navigation
